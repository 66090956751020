import { Approved } from "assets/icons/Approved";
import { Expired } from "assets/icons/Expired";
import { Reproved } from "assets/icons/Reproved";
import { format } from "date-fns";

type MedicationStatusProps = {
    status: any;
    isSubsidized: boolean;
    validDate: Boolean;
    dueDate?: string;
    isGeneric: boolean;
    payrollDeduction: boolean;
    isReplaceable: boolean;
    medicationStatusText: string;
};

export function MedicationStatus({
    status,
    validDate,
    isSubsidized,
    dueDate,
    isGeneric,
    payrollDeduction,
    isReplaceable,
    medicationStatusText,
}: MedicationStatusProps) {
    function getLabelColor() {
        if (status === "Approved" && !validDate) {
            return "#424242";
        }

        switch (status) {
            case "Reproved":
                return "#CF2860";
            case "Approved":
                return "#3BA648";
        }
    }

    function getLabelText() {
        if (status === "Approved" && !validDate) {
            return `Validade expirada em: ${format(new Date(String(dueDate)), "dd/MM/yyyy")}`;
        }

        switch (status) {
            case "Reproved":
                return "Indisponível no plano •";
            case "Approved":
                return "Liberado pra compra •";
        }
    }

    function getIconType() {
        if (status === "Approved" && !validDate) {
            return <Expired />;
        }

        switch (status) {
            case "Reproved":
                return <Reproved />;
            case "Approved":
                return <Approved />;
        }
    }

    const getSubsized = (isSubsidized: boolean) => {
        if (isSubsidized && validDate) {
            return "Subsidiado •";
        } else {
            return validDate && status === "Approved" && "Não subsidiado •";
        }
    };

    const getGeneric = (isGeneric: boolean) => {
        if (isGeneric && validDate) {
            return "Genérico •";
        } else {
            return null;
        }
    };

    const getPayrollDeduction = (payrollDeduction) => {
        if (payrollDeduction && validDate) {
            return "Desconto em folha •";
        } else {
            return null;
        }
    };

    const getIsReplaceable = (replaceable) => {
        if (replaceable) {
            return "Substituível •";
        } else {
            return null;
        }
    };

    return (
        <div data-testid="medication-status" className="flex cursor-default">
            {getIconType()}
            <span className="ml-1 text-base font-bold" style={{ color: getLabelColor() }}>
                {getLabelText()}
            </span>
            <span className="ml-1 text-base font-bold" style={{ color: getLabelColor() }}>
                {getSubsized(isSubsidized)}
            </span>
            <span className="ml-1 text-base font-bold" style={{ color: getLabelColor() }}>
                {getGeneric(isGeneric)}
            </span>
            <span className="ml-1 text-base font-bold" style={{ color: getLabelColor() }}>
                {getPayrollDeduction(payrollDeduction)}
            </span>
            <span className="ml-1 text-base font-bold" style={{ color: getLabelColor() }}>
                {getIsReplaceable(isReplaceable)}
            </span>
            <span className="ml-1 text-base font-bold" style={{ color: getLabelColor() }}>
                {medicationStatusText}
            </span>
        </div>
    );
}
